import * as React from 'react';
import { autobind } from 'core-decorators';

import { BingoData } from '../data';
import { Constants } from '../modules/constants';
import * as Color from 'color';
import { Config } from '../modules/config';
import { getTintFilterForHue } from '../modules/util';

export interface BingoCellProps {
    cellSize: number;
    task: BingoData.Task;
    replaceHandler: () => void;
}

export interface BingoCellState {
    hover: boolean;
    doneByPlayerOne: boolean;
    doneByPlayerTwo: boolean;
}

export interface BingoDoneMarkerProps {
    playerNumber: 1 | 2;
    corner: boolean;
}

export class BingoDoneMarker extends React.Component<BingoDoneMarkerProps, null> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className={"bingo-done-marker"} style={this.styleMarker()}></div>
    }

    private styleMarker(): React.CSSProperties {
        const playerTwo = (this.props.playerNumber == 2);
        const markerImage = (playerTwo ? Config.doneMarkerImagePlayerTwo : Config.doneMarkerImage);
        const tint = playerTwo ? Config.doneMarkerImagePlayerTwoTint : Config.doneMarkerImageTint;
        const backgroundSize = (Config.doneMarkerSize === "100%" ?
                                (this.props.corner ? "66% 66%" : "contain") :
                                (this.props.corner ?  "50% 50%" : "75% 75%"));
        const backgroundPosition = this.props.corner ? (Config.twoPlayerMarkingMode === "\\" ? (playerTwo ? "right bottom" : "left top") : (playerTwo ? "left bottom" : "right top")) : "center";
        return {
            backgroundImage: `url("img/${markerImage}")`,
            backgroundBlendMode: "multiply",
            width: "100%",
            height: "100%",
            opacity: Config.doneMarkerOpacity,
            backgroundSize: backgroundSize,
            backgroundRepeat: "no-repeat",
            backgroundPosition: backgroundPosition,
            position: "absolute",
            // filter: "drop-shadow(5px 5px 5px black) drop-shadow(0px 0px 20px black)",
            filter: `drop-shadow(0.4vmin 0.4vmin 1vmin black) ${getTintFilterForHue(tint)}`,
            // transform: `scale(${playerTwo ? 0.8 : 1.0})`,
            top: 0,
            left: 0
        }
    }
}

export class BingoCell extends React.Component<BingoCellProps, BingoCellState> {

    constructor(props) {
        super(props);
        const freeSpot = (this.props.task.category.id == "FREE");
        this.state = { hover: false, doneByPlayerOne: freeSpot ? true : false, doneByPlayerTwo: Config.playerTwoEnabled && freeSpot ? true : false };
    }

    render() {
        return <div style={this.styleCell()} onMouseEnter={this.setHover} onMouseLeave={this.unsetHover} onClick={this.handleLeftClick} onContextMenu={this.handleRightClick}>
            {/* {Config.showCategoryName ? <span style={this.styleCategoryText()}>{this.props.task.category.name}</span> : null }
            {this.state.doneByPlayerOne ? <BingoDoneMarker playerNumber={1} /> : null}
            {this.state.doneByPlayerTwo ? <BingoDoneMarker playerNumber={2} /> : null}
            <span style={this.styleText()}>{this.props.task.name}</span> */}
            {this.renderCategoryName()}
            {this.renderTaskName(true)}
            {this.renderPlayerMarkers()}
            {this.renderTaskName(false)}
        </div>
    }

    private styleCell(): React.CSSProperties {
        const CELL_BORDER_PERCENTAGE = 0.05;
        const cellBorderAmount = this.props.cellSize * CELL_BORDER_PERCENTAGE;
        const actualCellSize = this.props.cellSize - 2 * (cellBorderAmount);
        const backgroundColor = new Color(this.getCellBackgroundColor()).darken(this.state.hover ? 0.1 : 0).toString();
        return {
            display: "block",
            boxSizing: "border-box",
            height: "100%",
            width: "100%",
            lineHeight: `${actualCellSize}vmin`,
            textAlign: "center",
            position: "relative",
            backgroundColor: backgroundColor,
            padding: `${cellBorderAmount}vmin`,
            borderBottom: "solid 2px #000000",
            borderRight: "solid 2px #000000",
            boxShadow: "inset -1px -1px 20px rgba(0,0,0,0.33), inset 1px 1px 20px rgba(0,0,0,0.25)",
            cursor: this.state.hover ? "pointer" : "auto"
        }
    }

    private renderCategoryName() {
        if (Config.showCategoryName) {
            return <span style={this.styleCategoryText()}>{this.props.task.category.name}</span>
        }
        return null;
    }

    private renderPlayerMarkers() {
        const markers = [];
        if (this.state.doneByPlayerOne) {
            markers.push(<BingoDoneMarker playerNumber={1} corner={Config.twoPlayerMarkingMode !== "full"} />);
        }
        if (this.state.doneByPlayerTwo) {
            markers.push(<BingoDoneMarker playerNumber={2} corner={Config.twoPlayerMarkingMode !== "full"} />);
        }
        return markers;
    }

    private renderTaskName(background: boolean) {
        if (background || Config.markerFadingEnabled) {
            return <span className={background ? null : "animate-fade-text"} style={this.styleText(background)}>{this.props.task.name}</span>;
        }
    }

    private styleCategoryText(): React.CSSProperties {
        const lineFactor = 7;
        const lineHeight = (this.props.cellSize) / lineFactor;
        const fontHeight = (this.props.cellSize) / (lineFactor + 1);
        const textColor = new Color(this.getCellBackgroundColor()).lighten(this.state.hover ? 0.1 : 0.33).alpha(this.done ? 0.75 : 1).toString();
        const outlineColor = new Color(textColor).darken(0.75).alpha(this.done ? 0 : 1).toString();
        const outline = `-1px 1px 0 ${outlineColor}, 1px 1px 0 ${outlineColor}, 1px -1px 0 ${outlineColor}, -1px -1px 0 ${outlineColor}`;
        return {
            display: "inline-block",
            position: "absolute",
            top: `0vmin`, //PoP hackt
            left: `${lineHeight / 4}vmin`,
            lineHeight: `${lineHeight}vmin`,
            fontSize: `${lineHeight - 1}vmin`,
            textAlign: "left",
            overflow: "hidden",
            color: Constants.TILE_CATEGORY_TEXT_COLOR || textColor,
            textShadow: outline,
        }
    }

    private styleText(background: boolean): React.CSSProperties {
        const textLength = this.props.task.name.length;
        const lineFactor = 5 + Math.floor(textLength / 15.0);
        const lineHeight = (this.props.cellSize) / lineFactor;
        const fontHeight = (this.props.cellSize) / (lineFactor + 2);
        const textColor = new Color(Constants.TILE_TASK_TEXT_COLOR).alpha((background && this.done) ? 0.5 : 1).toString();
        const outlineColor = new Color("black").alpha((background && this.done) ? 0 : 1).toString();
        const backgroundOutline = `-1px 1px 0 ${outlineColor}, 1px 1px 0 ${outlineColor}, 1px -1px 0 ${outlineColor}, -1px -1px 0 ${outlineColor}`;
        const foregroundOutline = `-3px 3px 3px ${outlineColor}, 3px 3px 3px ${outlineColor}, 3px -3px 3px ${outlineColor}, -3px -3px 3px ${outlineColor}`;
        return {
            display: "inline-flex",
            // verticalAlign: "middle",
            lineHeight: `${lineHeight}vmin`,
            fontSize: `${fontHeight}vmin`,
            textAlign: "center",
            color: textColor,
            textShadow: background ? backgroundOutline : foregroundOutline,
            // textShadow: "-1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000, 3px 3px 5px #000",
            hyphens: "auto",
            position: "absolute",
            top: 0,
            filter: (!background && !this.done) ? "opacity(0)" : undefined,
            left: 0,
            height: "100%",
            alignItems: "center",
        }
    }

    private get done(): boolean {
        return this.state.doneByPlayerOne || this.state.doneByPlayerTwo;
    }

    private getCellBackgroundColor() {
        return this.props.task.category.backgroundColor ?? Constants.TILE_DEFAULT_BACKGROUND;
    }

    @autobind
    private setHover() {
        this.setState({hover: true})
    }

    @autobind
    private unsetHover() {
        this.setState({hover: false})
    }

    @autobind
    private handleLeftClick() {
        this.setState({doneByPlayerOne: !this.state.doneByPlayerOne})
    }

    @autobind
    private handleRightClick(e) {
        e.preventDefault();
        if (Config.playerTwoEnabled) {
            this.setState({doneByPlayerTwo: !this.state.doneByPlayerTwo})
        } else {
            this.props.replaceHandler?.();
        }
    }

}